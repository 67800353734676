'use strict';

/*global Modernizr */
import store from './_store';
import helpers from './_helpers';
import ResponsiveDebug from './class/ResponsiveDebug';
//import Collapsible from './class/Collapsible';
//import ScrollAnchor from './class/ScrollAnchor';
//import ShowPassword from './class/ShowPassword';
//import ClearInput from './class/ClearInput';
import DetectBrowser from './class/DetectBrowser';
//import Cookies from './class/Cookies';
//import BannerMessages from './class/BannerMessages';
//import ValidForm from './class/ValidForm';
// import AnimatedLabelField from './class/AnimatedLabelField';
//import Colorbox from './class/Colorbox';
import AppGame from './class/AppGame';


/**
 *
 * App
 * Main Controller
 *
 * @author insign
 */

const app = {
  init: function () {
    if($('.onlyMarmite').length) {
      console.warn('/!\\ If you see this warning message, it means that your are in Marmite Styleguide.\n' +
        'If it\'s not the case, it means that someone has forgot to remove the class .onlyMarmite in dev template\n' +
        'and many js dev code won\'t work properly. :\'(' );
    }

    this.bindUI();

    /*--- initialisation des tailles de viewport ---*/
    store.currentWidth = store.wWidth = helpers.viewport().width;
    store.currentHeight = store.wHeight = helpers.viewport().height;

    store.wScroll = $(window).scrollTop();

    const self = this;


    /*--- responsive-debug ---*/
    const responsiveDebug = new ResponsiveDebug();

    /*--- detectBrowser ---*/
    const detectBrowser = new DetectBrowser();

    /*--- Validation Form ---*/
    /*const validForm = new ValidForm({
      /!*container: '.valid-form',
      fieldContainer: '.form-field',
      validClass: 'as--valid',
      invalidClass: 'as--invalid',
      msgErrorClass: 'form-msg-error',*!/
    });*/

    /*--- cookies ---*/
    //store.cookies = new Cookies();

    /*--- Banner messages (cookies consent / warnig / news...) ---*/
    /*const messagesBanner = new BannerMessages({
      //caping: 3,
    });*/

    /*--- Skip links ---*/
    /*const skip = new Collapsible({
      container: '.skip',
      cta: '.skip-cta',
      className: 'as--focused',
      optionHover: true
    });*/

    /*--- colorbox ---*/
    /*const colorbox = new Colorbox();*/

    /*--- animation scroll ---*/
    /* Use '.js-scroll-anchor' class to trigger smooth anchor scroll*/
    //store.scrollAnchor = new ScrollAnchor();

    /*--- password ---*/
    //const showPassword = new ShowPassword();

    /*--- clear input ---*/
    //const clearInput = new ClearInput();

    // responsive
    self.onResize();

    /*--- App game ---*/
    const appGame = new AppGame();
  },

  checkMobile: function () {
    if (/Android|webOS|iPhone|iPad|BlackBerry|Windows Phone|Opera Mini|IEMobile|Mobile/i.test(navigator.userAgent)) {
      return true;
    }
  },

  onResize: function () {
    const self = this;
  },

  onScroll: function () {
    const self = this;
  },

  bindUI: function () {
    const self = this;

    if (Modernizr.mq('only all')) {
      $(window).on('resize', function () {
        store.wWidth = helpers.viewport().width;
        store.wHeight = helpers.viewport().height;
        if (store.currentHeight !== store.wHeight || store.currentWidth !== store.wWidth) {
          store.currentHeight = store.wHeight;
          store.currentWidth = store.wWidth;
          /*--- timer pour le redimensionnement d'ecran ---*/
          clearTimeout(store.timerResponsive);
          store.timerResponsive = setTimeout(self.onResize.bind(self), 300);
        }
      });
    }

    document.onreadystatechange = function () {
      if (document.readyState === 'complete') {
        self.onResize();
      }
    };

    /*--- fonctions au scroll ---*/
    $(window).on('scroll', function () {
      store.wScroll = $(window).scrollTop();

      self.onScroll();
    });

  }
};

app.init();

// global custom functions, they can be called from anywhere within the project (useful for the back-end developers)
const customFunctions = {
  // global custom function example
  // to call it from anywhere : global.customFunction.afterAjaxExample();
  /*afterAjaxExample: function() {
   helpers.resizeImg('.media-block-news');
   }*/
};
// exports the elements that need to be accessed from somewhere else (in the "global" standalone object, cf. gulpfile)
module.exports = customFunctions;
