/* global alert */
/* global ga */
/* global Pizzicato */
/* global addthis_share */
/* global Modernizr */
import store from '../_store';

/**
 *
 * AppGame
 *
 *
 * @author efr
 */


class AppGame {

  /**
   *
   * Constructor
   *
   * @param options [object] List of settings
   */

  constructor(options = {}) {

    const defaults = {
      container: '.app-page',
      timer: '.app-timer',
      screen: {
        ratio: 1000,
        width: 10000,
        height: 10000,
        prevRatio: 0,
        prevWidth: 0,
        prevHeight: 0,
        refMinWidth: 667,
        refWidth: 1024,
        refHeight: 768,
      },
      lastErrorDuration: 1.5, // time in second
      sounds: {
        timer: {
          file: store.sRootPath + 'assets/sounds/Game-Timer.mp3',
          loop: true,
        },
        buttons: {
          //file: store.sRootPath + 'assets/sounds/Button.mp3',
          file: store.sRootPath + 'assets/sounds/Tap.mp3',
        },
        count3210: {
          file: store.sRootPath + 'assets/sounds/Collect-Item.mp3',
          //file: store.sRootPath + 'assets/sounds/Diamond.mp3',
        },
        selectPlate: {
          file: store.sRootPath + 'assets/sounds/Game-Jump.mp3',
          //file: store.sRootPath + 'assets/sounds/Game-Swoosh.mp3',
        },
        selectPlateForbidden: {
          file: store.sRootPath + 'assets/sounds/Error.mp3',
        },
        zoom: {
          file: store.sRootPath + 'assets/sounds/Window-Open.mp3',
        },
        unzoom: {
          file: store.sRootPath + 'assets/sounds/Window-Open.mp3',
        },
        dropPlateSuccess: {
          file: store.sRootPath + 'assets/sounds/Attainment.mp3',
          //file: store.sRootPath + 'assets/sounds/Buy-Item.mp3',
        },
        dropPlateForbidden: {
          file: store.sRootPath + 'assets/sounds/Error.mp3',
        },
        dropPlateError: {
          file: store.sRootPath + 'assets/sounds/Lose.mp3',
          //file: store.sRootPath + 'assets/sounds/Wrong.mp3',
        },
        endGame: {
          file: store.sRootPath + 'assets/sounds/Win.mp3',
        },
        challengeCounting: {
          file: store.sRootPath + 'assets/sounds/Counting-Scores.mp3',
          //file: store.sRootPath + 'assets/sounds/Scores-Counting.mp3',
          loop: true,
        }
      },
      originBO: window.location.hostname !== 'localhost' ? window.location.origin : 'https://phenomatrix.review.insign.fr',
      ranks: $('.app-screen').data('ranks').split('|'),
    };

    this.initGameData = {
      timer: 0,
      maxTime: 60 * 5, // ver5 min
      //maxTime: 20, // active to test shorter time
      state: 'init',
      playerId: '',
      activePlate: '',
      timing: null,
      colonyColors: ['77395a','b7813f','27aa84'], // grape (must stay in first), clay-brown, bluey-green
      colonyColorsVitekMS: ['b7813f','27aa84'], // clay-brown, bluey-green,
      score: '',
      playerName: '',
      playerNameDefault: $('.app-popup-ranking--table').data('yourname') || '',
      playerRank: -1,
    };

    this.initGamePlates = [
      {
        type: 'negative',
        count: 3,
      },
      {
        type: 'vitek2',
        count: 3,
      },
      {
        type: 'vitekMS',
        count: 3,
      },
      {
        type: 'trap',
        count: 1,
      },
    ];

    this.initGamePiles = {
      toTest: 10,
      founded: 0,
      error: 0,
      tested: 0,
      negative: 0,
      vitek2: 0,
      vitekMS: 0
    };

    //redirect to the good language page
    const savedLanguage = localStorage.getItem('phenomatrix-language');
    const navigatorLanguage = navigator.language;
    const pathname = window.location.pathname.split('/');
    const currentPage = pathname[pathname.length - 1];
    const allAvailableLanguage = ['en', 'fr', 'de', 'pt', 'es']; //
    const availableLanguage = ['fr', 'de', 'pt', 'es']; //


    //console.log('window.location : ', window.location);
    //console.log('savedLanguage : ', savedLanguage);
    //console.log('navigatorLanguage : ', navigatorLanguage);
    //console.log('pathname : ', pathname);
    //console.log('currentPage : ', currentPage);
    //console.log('inArray : ', $.inArray(currentPage, ['', 'index.html']));

    // do redirect only if he are only on index.html
    if($.inArray(currentPage, ['', 'index.html']) !== -1 || navigatorLanguage.indexOf('en-') !== -1 ) {
      //console.log('We are on index.html and navigator not in `en`');

      if(savedLanguage !== null && savedLanguage !== undefined && savedLanguage !== 'undefined') {
        // redirect to saved language
        //console.log('redirect to saved language : ', savedLanguage);
        window.location.href = './index-' + savedLanguage + '.html';

      } else {
        // redirect to existing page language sets by navigator
        // if existing in available languages
        let redirectedToAvailableNavigator = false;
        $.each(availableLanguage, function (index, lang) {

          //console.log('navigatorLanguage.indexOf(lang) : ', navigatorLanguage.indexOf(lang));
          if (navigatorLanguage.indexOf(lang + '-') >-1 || navigatorLanguage.toLowerCase() === lang ) {
            //console.log('redirect to navigator language : ', lang);
            redirectedToAvailableNavigator = true;
            window.location.href = './index-' + lang + '.html';
            return false; // break
          }
        });

        if(!redirectedToAvailableNavigator) {
          //console.log('Navigator not in available languages ');
          //console.log('redirect to index-en.html');
          window.location.href = './index-en.html';
        }
      }

    } else {
      //console.log('We are not on index.html or on index.html and in `en`');
      //console.log('=> no redirect');
    }



    // fusionne les options renseignees avec celles par defaut pour creer
    // l'objet settings
    this.settings = $.extend({}, defaults, options);
    this.hash = window.location.hash || null;
    switch (this.hash) {
      case '#developer-mode--on' :
        localStorage.setItem('developer-mode', 'true');
        break;
      case '#developer-mode--off' :
        localStorage.setItem('developer-mode', 'false');
        break;
      case '#developer-mode--no-chrono--on' :
        localStorage.setItem('developer-mode--no-chrono', 'true');
        break;
      case '#developer-mode--no-chrono--off' :
        localStorage.setItem('developer-mode--no-chrono', 'false');
        break;
      default:
      //
    }

    this.developerMode = localStorage.getItem('developer-mode') && localStorage.getItem('developer-mode') === 'true';
    if(this.developerMode === null) {
      this.developerMode = false;
    }

    this.developerModeNoChrono = localStorage.getItem('developer-mode--no-chrono') && localStorage.getItem('developer-mode--no-chrono') === 'true';
    if(this.developerModeNoChrono === null) {
      this.developerModeNoChrono = false;
    }
    //console.log('this.developerMode : ', this.developerMode);
    if (this.developerMode === true) {
      $('html').addClass('developer-mode');
    }

    this.settings.disableSounds = false;
    this.settings.forceDisableSounds = localStorage.getItem('disable-sounds') && localStorage.getItem('disable-sounds') === 'true';
    if(this.settings.forceDisableSounds === null) {
      this.settings.forceDisableSounds = false;
    }
    if(this.developerMode === true) { // force sound off if developer mode
      this.settings.forceDisableSounds = true;
    }

    //console.log('userAgent : ', navigator.userAgent);
    //console.log('this.developerMode : ', this.developerMode);
    //console.log('this.settings.disableSounds : ', this.settings.disableSounds);
    //console.log('this.settings.forceDisableSounds : ', this.settings.forceDisableSounds);


    this.initPreload();

    // evenements par utilisateur
    this.bindUIActions();

    this.initGame();
  }

  /**
   *
   * Bind UI Actions
   *
   */

  bindUIActions() {
    const self = this;

    //console.log('AppGame : ', self.settings);
    function activeRules() {
      //console.log('activeRules');
      $('.app-screen').removeClass('is-active').addClass('is-hidden');
      $('.app-screen-rules').addClass('is-active').removeClass('is-hidden');
    }
    function activeTuto() {
      //console.log('activeTuto');
      $('.app-screen').removeClass('is-active').addClass('is-hidden');
      $('.app-screen-tuto').addClass('is-active').removeClass('is-hidden');
    }
    if(!Modernizr.ie) {
      $('body').on('click', '.app-screen-preloader--counter', function () {
        self.initSounds();
      });

      $('body').on('change', '#app-btn-sound', function () {
        //console.log('toggle sound');
        self.settings.disableSounds = !self.settings.disableSounds;

        $.each(self.settings.sounds, function(keySound, sound) {
          sound.audio.volume = self.settings.disableSounds ? 0 : 1;
          if(self.developerMode === false) {
            localStorage.setItem('disable-sounds', self.settings.disableSounds ? 'true' : 'false');
          }
        });
      });
    }

    $('body').on('click', '.a-button:not(.as--no-sound)', function () {
      self.playSound('buttons');
    });


    // simulate click on a button to force load on iOs
    $('.app-screen-preloader--counter').trigger('click');
    $('body').on('click', '.app-btn-play, .app-screen-tuto .app-btn-ok', activeRules);

    // jquery-mobile not working with jquery 3.3.1
    // $('.app-screen-tuto').on('swiperight', activeRules);

    $('body').on('click', '.app-screen-rules .app-btn-ok, .app-screen-rules .app-btn-go', activeTuto);

    // jquery-mobile not working with jquery 3.3.1
    // $('.app-screen-rules').on('swipeleft', activeTuto);

    $('body').on('click', '.app-btn-trophy, .form-btn-trophy', function () {

      let dataSend = {};
      if(self.gameData.playerId !== ''){
        $.extend(dataSend, {
          playerId: self.gameData.playerId,
        });
      }

      if($('.app-popup-saveYourScore').data('bPopup')) {
        $('.app-popup-saveYourScore').data('bPopup').close();
      }

      $.ajax ({
        url: self.settings.originBO + '/bo/api/get-firsts/' +  self.gameData.eventUrl,
        //url: 'is_active_true.json',
        data: dataSend,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
          //console.log('Success ranking ', data);

          const $popup = $('.app-popup-ranking');
          $popup.data('bPopup', $popup.bPopup({
            closeClass: 'app-btn-close',
            modalColor: '#333',
            opacity: 0.9,
          }));

          self.displayRanking(data);
        },
        error: function (error) {
          console.error('Error du clic Ajax : ', error);
          //self.eventPageNotAllowed();
        }
      });
    });

    $('body').on('click', '.app-screen-tuto .app-btn-go', function () {
      $('.app-screen').removeClass('is-active').addClass('is-hidden');
      $('.app-screen-game').addClass('is-active').removeClass('is-hidden');
      self.initCountDown();
    });

    $('body').on('click', '.app-plate-active', function () {
      $('.app-screen-game').toggleClass('plate-is-zoomed');

      self.playSound($('.app-screen-game.plate-is-zoomed').length ?'zoom' : 'unzoom');
    });

    $('body').on('click', '.app-layer-plate-zoomed', function () {
      $('.app-screen-game').removeClass('plate-is-zoomed');

      self.playSound('unzoom');
    });

    $('body').on('click', '.app-btn-retry', function () {
      const that = $(this);
      $(this).addClass('is-clicked');
      setTimeout(function () {
        $('.app-screen').removeClass('is-active').addClass('is-hidden');
        $('.app-screen-game').addClass('is-active').removeClass('is-hidden');
        self.stopGame();
        self.initGame();
        self.initCountDown();
        that.removeClass('is-clicked');
      }, 300);
    });

    $('body').on('click', '.app-btn-pause', function () {
      self.pauseGame();
    });

    $('body').on('click', '.app-btn-pause-state, .app-btn-resume', function () {
      self.playGame();
    });

    $('body').on('click', '.app-pile-toTest', function () {
      self.pickPlate();
    });

    $('body').on('click', '.app-screen-game .app-pile', function () {

      if(self.gameData.activePlate === '') {
        self.playSound('dropPlateForbidden');
        $(this).addClass('is-shaking').delay(1000).queue(function(){
          $(this).removeClass('is-shaking').dequeue();
        });
        return false;
      }

      const type = $(this).data('type');
      self.sortPlate(type);
    });

    $('body').on('click', '.app-btn-pin-it', function () {
      const $popup = $('.app-popup-pin-tuto');
      $popup.data('bPopup', $popup.bPopup({
        closeClass: 'app-btn-close',
        modalColor: '#333',
        opacity: 0.9,
      }));
    });

    $('body').on('click', '.app-privacy-policy--btn', function () {
      const $popup = $('.app-popup-privacy-policy');
      $popup.data('bPopup', $popup.bPopup({
        closeClass: 'app-btn-close',
        modalColor: '#333',
        opacity: 0.9,
      }));
    });

    $('body').on('click', '.app-save-your-score-button', function () {
      const $popup = $('.app-popup-saveYourScore');
      $popup.data('bPopup', $popup.bPopup({
        closeClass: 'app-btn-close',
        modalColor: '#333',
        opacity: 0.9,
      }));
    });

    $('body').on('click', '.app-screen-end--challenge, .app-screen-challenge--retry', function () {
      $('.app-popup-challenge input[type=number]').val(''); // reset input if challenge again
      const $popup = $('.app-popup-challenge');
      $popup.data('bPopup', $popup.bPopup({
        closeClass: 'app-btn-close',
        modalColor: '#333',
        opacity: 0.9,
      }));

      if($('html.no-touchevents').length) {
        // focus only on no touch screen to avoid display of keyboard
        $popup.find('input[type=number]').focus();
      }
    });

    $('body').on('submit', '.app-popup-challenge form', function (event) {
      event.stopPropagation();
      event.preventDefault();
      self.challengePhenoMATRIX();
    });

    $('body').on('click', '.share--addthis > button, .switch-lang > button', function (event) {
      $(this).toggleClass('is-focus');
    });

    $('body').on('click', '.switch-lang a', function (event) {
      localStorage.setItem('phenomatrix-language', $(this).data('lang'));
    });

    $('body').on('click', function (event) {
      if(!$(event.target).closest('.share--addthis').length) {
        $('.share--addthis > button').removeClass('is-focus');
      }
      if(!$(event.target).closest('.switch-lang').length) {
        $('.switch-lang > button').removeClass('is-focus');
      }
    });

    // visible if developer mode is enable
    $('body').on('click', '.app-dev-btn-start', function () {
      $('.app-pile-toTest').removeClass('is-tilting').delay(1000).queue(function (next) {
        $(this).addClass('is-tilting');
        next();
      }).delay(2000).queue(function () {
        $(this).removeClass('is-tilting').dequeue();
      });
      self.playGame();
    });
    $('body').on('click', '.app-dev-btn-end', function () {
      self.endGame();
    });

    $(window).on('resize', function () {
      self.testResize();
    });
    $(window).trigger('resize');

    /* Tracking CTA */

    $('body').on('click', '.app-btn-play', function () {
      self.tracking({
        eventAction: 'Play now',
        eventLabel: 'Start'
      });
    });

    $('body').on('click', '.app-screen-intro .app-btn-trophy', function () {
      self.tracking({
        eventAction: 'Event popin results - Link',
        eventLabel: 'Start'
      });
    });

    $('body').on('click', '.app-screen-rules .app-btn-ok', function () {
      self.tracking({
        eventAction: 'I Get it',
        eventLabel: 'How to Play 1'
      });
    });

    $('body').on('click', '.app-screen-tuto .app-btn-go', function () {
      self.tracking({
        eventAction: 'Let’s play',
        eventLabel: ' How to Play 2'
      });
    });

    $('body').on('click', '.app-btn-pause', function () {
      self.tracking({
        eventAction: 'Pause',
        eventLabel: 'Gameplay'
      });
    });

    $('body').on('click', '.app-btn-pause-state', function () {
      self.tracking({
        eventAction: 'Play',
        eventLabel: 'Pause Screen'
      });
    });

    $('body').on('click', '.app-btn-resume', function () {
      self.tracking({
        eventAction: 'Resume',
        eventLabel: 'Pause Screen'
      });
    });

    $('body').on('click', '.app-screen-pause .app-btn-trophy', function () {
      self.tracking({
        eventAction: 'Event popin results - Link',
        eventLabel: 'Pause Screen'
      });
    });

    $('body').on('click', '.app-plate-active button', function () {
      if($('.app-screen-game.plate-is-zoomed').length) {
        self.tracking({
          eventAction: 'Zoom (open/close)',
          eventLabel: ' Gameplay '
        });
      }
    });

    $('body').on('click', '.app-pile-toTest', function () {
      self.tracking({
        eventAction: 'Select a plate - ' + ($('.app-screen-game.table-is-empty').length ? 'OK' : 'Forbidden'),
        eventLabel: ' Gameplay '
      });
    });

    $('body').on('click', '.app-screen-end .app-btn-retry', function () {
      self.tracking({
        eventAction: 'Play again',
        eventLabel: 'Game Results'
      });
    });

    $('body').on('click', '.app-screen-end--challenge', function () {
      self.tracking({
        eventAction: 'Challenge Phenomatrix',
        eventLabel: 'Game Results'
       });
    });

    $('body').on('click', '.app-screen-end--learn-more', function () {
      self.tracking({
        eventAction: 'Learn More',
        eventLabel: 'Game Results'
      });
    });

    $('body').on('click', '.app-screen-end .addthis_button_facebook', function () {
      self.tracking({
        eventAction: 'Facebook',
        eventLabel: 'Game Results'
      });
    });

    $('body').on('click', '.app-screen-end .addthis_button_linkedin', function () {
      self.tracking({
        eventAction: 'Linkedin',
        eventLabel: 'Game Results'
      });
    });

    $('body').on('click', '.app-screen-end .addthis_button_email', function () {
      self.tracking({
        eventAction: 'Email',
        eventLabel: 'Game Results'
      });
    });

    $('body').on('click', '.app-screen-end .addthis_button_link', function () {
      self.tracking({
        eventAction: 'Link',
        eventLabel: 'Game Results'
      });
    });

    $('body').on('click', '.app-screen-end .app-btn-trophy', function () {
      self.tracking({
        eventAction: 'Event popin results - Link',
        eventLabel: 'Game Results'
      });
    });

    $('body').on('click', '.app-screen-end--save-your-score', function () {
      self.tracking({
        eventAction: 'Event - save score - link',
        eventLabel: 'Game Results'
      });
    });

    $('body').on('submit', '.app-popup-saveYourScore form', function () {
      self.tracking({
        eventAction: 'Event - save score - submit',
        eventLabel: 'Event save score'
      });
    });

    $('body').on('submit', '.form-btn-trophy', function () {
      self.tracking({
        eventAction: 'Event - save score - submit',
        eventLabel: 'Event save score'
      });
    });

    $('body').on('click', '.app-popup-saveYourScore--validate button', function () {
      self.tracking({
        eventAction: 'Event popin results - Link',
        eventLabel: 'Event save score'
      });
    });

    $('body').on('click', '.app-screen-challenge .app-btn-retry', function () {
      self.tracking({
        eventAction: 'Play again',
        eventLabel: 'PhénoMATRIX Results'
      });
    });

    $('body').on('submit', '.app-popup-challenge form', function () {
      self.tracking({
        eventAction: 'Launch Phenomatrix',
        eventLabel: 'Phenomatrix Challenge'
      });
    });

    $('body').on('click', '.app-screen-challenge--cta', function () {
      self.tracking({
        eventAction: 'Learn More',
        eventLabel: 'PhénoMATRIX Results'
      });
    });

    $('body').on('click', '.app-screen-challenge .addthis_button_facebook', function () {
      self.tracking({
        eventAction: 'Facebook',
        eventLabel: 'PhénoMATRIX Results'
      });
    });

    $('body').on('click', '.app-screen-challenge .addthis_button_linkedin', function () {
      self.tracking({
        eventAction: 'Linkedin',
        eventLabel: 'PhénoMATRIX Results'
      });
    });

    $('body').on('click', '.app-screen-challenge .addthis_button_email', function () {
      self.tracking({
        eventAction: 'Email',
        eventLabel: 'PhénoMATRIX Results'
      });
    });

    $('body').on('click', '.app-screen-challenge .addthis_button_link', function () {
      self.tracking({
        eventAction: 'Link',
        eventLabel: 'PhénoMATRIX Results'
      });
    });

    this.updateOnlineStatus();
    window.addEventListener('online',  this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);

    // Event page actions
    $('body').on('click', '.app-save-your-score-button', function () {
      $('.app-popup-saveYourScore--validate').html('')
        .removeClass('is-active success unsuccess');
      $('#form_name').val('');
    });

    $('body').on('submit', '.app-popup-saveYourScore--form form', function (event) {
      event.stopPropagation();
      event.preventDefault();

      //console.log('submit saveYourScore');

      const $form = $(this);

      if($form.data('submited') && $form.data('submited') === true) {
        return false;
      }

      $form.data('submited', true);

      //self.submitUserRanking();

      //console.log('serializeArray ', $(this).serializeArray());
      //console.log('serialize ', $(this).serialize());

      self.gameData.playerName = $('#form_name').val().trim();

      const data = {
        name: self.gameData.playerName,
        eventUrl: $('#form_eventName').val(),
        score: $('#form_score').val(),
        time: $('#form_time').val(),
        token: $('#form__token').val()
      };
      const messageValidate = $('.app-popup-saveYourScore--validate');
      const messageValidateDataOk = $('.app-popup-saveYourScore').data('confirmationok');
      const messageValidateDataKo = $('.app-popup-saveYourScore').data('confirmationko');
      const messageValidateDataRefused = $('.app-popup-saveYourScore').data('confirmationrefused');
      const messageValidateButton = $('.app-save-your-score-saved').data('scoresaved');

      const saveScoreOK = (data)=>{
        messageValidate.html('');

        messageValidate.addClass('is-active success').removeClass('unsuccess');
        messageValidate.append(messageValidateDataOk);
        self.gameData.playerId = data.player_id;
        $form.hide();
        //console.log('data', data);
        $('.app-save-your-score-button').hide();
        $('.app-save-your-score-saved').html(messageValidateButton);

      };

      const saveScoreKO = (error)=>{
        messageValidate.html('');
        messageValidate.addClass('is-active unsuccess').removeClass('success');

        messageValidate.append(error === 'REFUSED' ? messageValidateDataRefused : messageValidateDataKo);
      };


      $.ajax ({
        method: 'POST',
        url: self.settings.originBO + '/bo/api/save-score',
        data: {data:JSON.stringify(data)},
        //dataType: 'json',
        success: function (data) {
          //console.log('Données du formulaire bien envoyée ');
          if(data.status === 'OK') {
            saveScoreOK(data);
          } else {
            saveScoreKO(data.status);
          }

          //console.log('self.gameData.playerId', self.gameData.playerId);
        },
        error: function (error) {
          console.error('Erreur lors de l\'envoi du formulaire');
          saveScoreKO('error');
        },
        complete:function () {
          $form.data('submited', false);
        },
      });

    });
  }

  updateOnlineStatus(event) {
    const online = navigator.onLine;
    //console.log('updateOnlineStatus - online : ', online);

    if(online) {
      $('html').removeClass('app-is-offline').addClass('app-is-online');
    } else {
      $('html').addClass('app-is-offline').removeClass('app-is-online');
    }
  }

  /**
   *
   * Tracking
   *
   */

  tracking(infos) {

    const trackingInfos  = $.extend({}, {
      hitType: 'event',
      eventCategory: 'CTA',
      eventAction: '',
      eventLabel: ''
    }, infos);
    //console.log('trackingInfos : ', trackingInfos);

    if(typeof ga !== 'undefined' ) {
      //console.log('ga : ', ga);
    } else {
      //console.log('ga is undefined -> do nothing');
    }

  }

  /**
   *
   * Start Game
   *
   */

  initPreload() {
    const perfData = window.performance.timing; // The PerformanceTiming interface represents timing-related performance information for the given page.
    const EstimatedTime = -(perfData.loadEventEnd - perfData.navigationStart);
    const minReadingTime = 5; // min time to have time to read Disclamer
    const time = Math.max(parseInt((EstimatedTime/1000)%60)*100, minReadingTime * 1000);

// Percentage Increment Animation
    const PercentageID = '#app-screen-preloader--counter';
    const start = 0;
    const end = 100;

    setTimeout(function () {
      window.scrollTo(0, 1);
    }, 1000);

    function animateValue(id, start, end, duration) {

      const range = end - start;
      let current = start;
      const increment = end > start? 1 : -1;
      const stepTime = Math.abs(Math.floor(duration / range));
      const obj = $(id);

      var timer = setInterval(function() {
        current += increment;
        $(obj).text(current + '%');
        if (current === end) {
          clearInterval(timer);
        }
      }, stepTime);
    }

    animateValue(PercentageID, start, end, time);

// Fading Out Loader on Finised
    setTimeout(function(){
      $('.app-screen-preloader').removeClass('is-active').addClass('is-hidden');
    }, time);
  }

  initCountDown() {
    const self = this;
    var timeleft = 3;
    $('.app-screen-countdown').addClass('is-active').removeClass('is-hidden');
    $('#countdown-timer').text(timeleft).addClass('is-pulsing');

    self.playSound('count3210');

    var downloadTimer = setInterval(function () {
      timeleft--;
      $('#countdown-timer').text(timeleft);

      self.playSound('count3210');

      if (timeleft <= 0) {
        clearInterval(downloadTimer);
        $('.app-pile-toTest').removeClass('is-tilting').delay(1000).queue(function (next) {
          $(this).addClass('is-tilting');
          next();
        }).delay(1500).queue(function () {
          $(this).removeClass('is-tilting').dequeue();
        });
        self.playGame();
        setTimeout(function () {
          $('#countdown-timer').removeClass('is-pulsing');
        }, 150);
      }
    }, 1000);
  }

  initSounds() {
    const self = this;

    $.each(self.settings.sounds, function(keySound, sound){
      //console.log('keySound : ', keySound);
      //console.log('sound : ', sound);

      sound.audio =  new Pizzicato.Sound({
        source: 'file',
        options: {
          path: sound.file,
          loop: sound.loop || false,
        }
      }, function() {
        //console.log('sound file loaded!');
      });
    });

    if (self.settings.forceDisableSounds) {
      $('#app-btn-sound').attr('checked','checked').prop('checked', true).trigger('change');
    }
  }

  /**
   *
   * Play Sound
   *
   * @param keySound [string] key of the sound to play once
   *
   */

  playSound(keySound) {
    const self = this;
    if(!Modernizr.ie) {
      const audio = self.settings.sounds[keySound].audio;
      //console.log('playSound : ', keySound);
      //console.log('audio : ', audio);

      //audio.stop();
      audio.play();
    }
  }

  /**
   *
   * Init Game
   *
   */

  initGame() {
    const self = this;
    //console.log('initGame : ', self.initGameData);

    self.gameData = $.extend({}, self.initGameData);

    self.testEventPageAvailaibility();

    self.gameData.piles = $.extend({}, self.initGamePiles);
    $('.app-founded-counter span').text(self.gameData.piles.founded);
    self.gameData.plates = JSON.parse(JSON.stringify(self.initGamePlates));



    self.gameData.randomIds = [];
    for (let i = 1000; i < 10000; i++) {
      self.gameData.randomIds.push(i);
    }

    // init values
    $(self.settings.timer).find('span').html(self.getClockTime(self.gameData.timer));

    $(self.settings.container).attr('data-state', 'init');
    $('.app-screen-game').addClass('table-is-empty');
    $('.app-page').removeClass('challenge-screen-is-opened');
    $('.is--finished').removeClass('is--finished');
    $('.as--long-time').removeClass('as--long-time');
    $('.app-popup-challenge input[type=number]').val('');
    $('.app-computer').addClass('is-empty');
    $('.app-save-your-score-saved').html('');

    self.clearComputer();

    // after all data change
    self.checkGame();

    $('.app-popup-saveYourScore--form form').show();
  }

  /**
   *
   * Play Game
   *
   */

  playGame() {
    const self = this;
    $('.app-screen').removeClass('is-active').addClass('is-hidden');
    $('.app-screen-game').addClass('is-active').removeClass('is-hidden');
    //console.log('playGame : ', self.gameData);
    if(!Modernizr.ie) {
      self.settings.sounds.timer.audio.play();
    }


    $(self.settings.container).attr('data-state', 'play');
    if(!self.developerModeNoChrono) {
      self.gameData.timing = setInterval(function () {
        self.gameData.timer++;
        $(self.settings.timer).find('span').html(self.getClockTime(self.gameData.timer));
        if (self.gameData.timer >= self.gameData.maxTime) {
          self.endGame();
        }
      }, 1000);
    }
  }

  /**
   *
   * Pause Game
   *
   */

  pauseGame() {
    const self = this;
    $(self.settings.container).attr('data-state', 'pause');
    $('.app-screen').removeClass('is-active').addClass('is-hidden');
    $('.app-screen-pause').addClass('is-active').removeClass('is-hidden');
    //console.log('pauseGame : ', self.gameData);
    clearInterval(self.gameData.timing);
    if(!Modernizr.ie) {
      self.settings.sounds.timer.audio.pause();
    }
  }

  /**
   *
   * Pick a plate
   *
   */

  pickPlate() {
    const self = this;
    //console.log('pickPlate : ', self.gameData);

    if(self.gameData.activePlate !== '') {
      self.playSound('selectPlateForbidden');
      $('.app-pile-toTest').addClass('is-shaking').delay(1000).queue(function(){
        $(this).removeClass('is-shaking').dequeue();
      });
      return false;
    }

    self.gameData.piles.toTest--;

    // get unique id of plate
    const plateIndex = Math.floor(self.gameData.randomIds.length * Math.random());
    const plateId = '#' + self.gameData.randomIds[plateIndex];
    self.gameData.randomIds.splice(plateIndex, 1);


    // get random plate
    const randomPlate = Math.floor(self.gameData.plates.length * Math.random());
    const plateType = self.gameData.plates[randomPlate].type;
    self.gameData.plates[randomPlate].count--;
    //console.log('randomPlate : ', randomPlate);
    //console.log('plateType : ', plateType);
    //console.log('plateTypeRemaining : ', plateTypeRemaining);
    self.gameData.activePlate = plateType;
    self.gameData.activePlateId = plateId;

    $('.app-dev-label').html(plateType);
    $('.app-plate-active--id').html(plateId);

    self.randomColonyPlate();
    self.updateComputer();

    const plateTypeRemaining = self.gameData.plates[randomPlate].count;
    if (plateTypeRemaining === 0) {
      // no more plate of this type =>
      // remove plate type on remaning array
      self.gameData.plates.splice(randomPlate, 1);
    }

    self.playSound('selectPlate');

    // after all data change
    self.checkGame();
  }

  /**
   *
   * Generate random colony in plate
   *
   */

  randomColonyPlate() {
    const self = this;
    //console.log('randomColonyPlate : ', self.gameData);

    const $plateActive = $('.app-plate-active');

    // remove previous colony in plate
    $plateActive.find('.app-plate-active--colony-ray').remove();
    const plateType = self.gameData.activePlate;
    const nbRays = 360;
    const rays = [];
    const raysSelected = [];
    const nbColonies = plateType === 'negative' ?
      Math.floor(Math.random() * 7) :
      7 +  Math.floor(Math.random() * 15);
    const nbColoniesTrap = plateType === 'trap' ?
      nbColonies - Math.floor(Math.random() * 6) - 1 :
      -1;
    $('.app-dev-label').append(' :<br>' + nbColonies + ' colonies');
    for (let i = 0; i < nbRays; i++) {
      rays.push(i);
    }
    for (let i = 0; i < nbColonies; i ++) {
      const raySelected = Math.floor(Math.random() * rays.length);
      raysSelected.push(rays[raySelected]);
      rays.splice(raySelected - 2, 5);
    }
    const randomColor = ( plateType === 'vitek2' ? // force bur
        self.gameData.colonyColors[0] : // force burgondy for vitek2
        ( plateType === 'negative' ?
          // random on all available color if negative
          self.gameData.colonyColors[Math.floor(Math.random() * self.gameData.colonyColors.length)] :
          // random on vitekMS available color if vitekMS or trap
          self.gameData.colonyColorsVitekMS[Math.floor(Math.random() * self.gameData.colonyColorsVitekMS.length)])
    );

    //console.log('nbColoniesTrap : ', nbColoniesTrap);
    //console.log('raysSelected : ', raysSelected);


    for (let i = 0; i < raysSelected.length; i++) {
      const $colonyRay = $('<span>', {
        class: 'app-plate-active--colony-ray'
      });
      const $colony = $('<i>', {
        class: 'app-plate-active--colony'
      });

      const minWidth = self.settings.screen.refMinWidth;
      const maxWidth = self.settings.screen.refWidth;
      const coefRes = 1 + (Math.min(maxWidth, Math.max(minWidth, $(window).width())) - minWidth) / minWidth;

      const randomColonyRadius = i > nbColoniesTrap ?
        (Math.floor(Math.random() * 2) * 5 + 6) / 10 + 'rem' : // random radius on non trap
        1; // force radius to 1 on trap colony

      if(i <= nbColoniesTrap) {
        $colony.addClass('trap');
      }

      $colonyRay.append($colony);
      $plateActive.append($colonyRay);

      $colonyRay.css({
        transform: 'rotate(' + (raysSelected[i] * 360 / nbRays) + 'deg)',
      });
      $colony.css({
        marginLeft: (Math.random() * 40 + 35) / 10 + 'rem',
        width: randomColonyRadius,
        height: randomColonyRadius,
        transform: 'rotate(' + (-1 * raysSelected[i] * 360 / nbRays) + 'deg)',
        background: '#' + randomColor
      });
    }

  }

  /**
   *
   * Update computer
   *
   */

  updateComputer() {
    const self = this;
    $('.app-computer').removeClass('is-empty');
    //console.log('updateComputer : ', self.gameData);
    $('.app-computer--plate-id').text(self.gameData.activePlateId);
    $('.app-computer--cytology > .' + self.gameData.activePlate).show();
  }

  /**
   *
   * Clear computer
   *
   */

  clearComputer() {
    $('.app-computer').addClass('is-empty');
    $('.app-computer--cytology > span').hide();
  }

  /**
   *
   * Sort a plate
   *
   */

  sortPlate(type) {
    const self = this;
    //console.log('sortPlate : ', self.gameData);

    const active = self.gameData.activePlate;

    self.gameData.piles.tested++;
    //console.log('type : ', type);
    if (type === active) {
      self.gameData.piles[type]++;
      self.goodResponse();
    }
    else if (type === 'vitekMS' && active === 'trap') {
      self.gameData.piles.vitekMS++;
      self.goodResponse();
    }
    else {
      self.badResponse();
    }
    self.gameData.activePlate = '';

    // after all data change
    self.checkGame();
  }

  /**
   *
   * Good response
   *
   */

  goodResponse() {
    const self = this;
    //console.log('goodResponse !!!');

    self.gameData.piles.founded++;

    $('.app-founded-counter span').text(self.gameData.piles.founded);
    $('.app-founded-counter').addClass('is-pulsing');
    setTimeout(function () {
      $('.app-founded-counter').removeClass('is-pulsing');
    }, 400);
    self.checkGame();

    self.playSound('dropPlateSuccess');
    if (self.gameData.piles.toTest === 0) {
      self.endGame();
    }
  }

  /**
   *
   * Bad response
   *
   */

  badResponse() {
    const self = this;
    //console.log('badResponse : ', self.gameData);
    const $errors = $('.app-popup-error li');
    //console.log('self.gameData.piles.error : ', self.gameData.piles.error);
    //console.log('$errors.length : ', $errors.length);
    $errors.hide().eq( Math.min(self.gameData.piles.error, $errors.length - 1) ).show();
    self.gameData.piles.error++;
    self.checkGame();

    self.playSound('dropPlateError');
    if (self.gameData.piles.toTest === 0) {

      $('.app-popup-error .app-btn-close').hide();
      const $popup = $('.app-popup-error');
      $popup.data('bPopup', $popup.bPopup({
        opacity: 0,
      }));

      setTimeout(function () {
        self.endGame();
      }, self.settings.lastErrorDuration * 1000);

      return false;
    }

    $('.app-popup-error').bPopup({
      closeClass: 'app-btn-close',
      opacity: 0,
    });

  }

  /**
   *
   * Stop Game
   *
   */

  stopGame() {
    const self = this;
    //console.log('stopGame : ', self.gameData);
    clearInterval(self.gameData.timing);
  }

  /**
   *
   * End Game
   *
   */

  endGame() {
    const self = this;
    //console.log('endGame : ', self.gameData);

    if($('.app-popup-error').data('bPopup')) {
      $('.app-popup-error').data('bPopup').close();
      $('.app-popup-error .app-btn-close').show();
    }
    self.stopGame();
    $('.app-screen').removeClass('is-active').addClass('is-hidden');
    $('.app-screen-end').addClass('is-active').removeClass('is-hidden');
    $(self.settings.container).attr('data-state', 'end');
    $('.app-screen-end--timer').text(self.getClockTime(self.gameData.timer));
    addthis_share.email_vars.time = self.getClockTime(self.gameData.timer);

    const phenoPlatesCount = Math.floor(self.gameData.timer * 2 / 3);
    $('.app-screen-end--pheno-count-plates').text(phenoPlatesCount);
    const $plates = $('.app-screen-end--player-count-plates');
    $plates.text(self.gameData.piles.tested).siblings('span').hide();
    if(self.gameData.piles.tested > 1) {
      $plates.siblings('span.label-plural').show();
    } else {
      $plates.siblings('span.label-single').show();
    }
    addthis_share.email_vars.plates = phenoPlatesCount;

    // manage count error
    $('.app-screen-end--errors, .app-screen-end--player .app-screen-end--no-error').hide();
    if(self.gameData.piles.error) {
      $('.app-screen-end--errors').show();
      const $errors = $('.app-screen-end--count-errors');
      $errors.text(self.gameData.piles.error).siblings('span').hide();
      if(self.gameData.piles.error > 1) {
        $errors.siblings('span.label-plural').show();
        //addthis_share.email_vars.errors = self.gameData.piles.error + $errors.siblings('span.label-plural').text();
      } else {
        $errors.siblings('span.label-single').show();
        //addthis_share.email_vars.errors = self.gameData.piles.error + $errors.siblings('span.label-single').text();
      }
    } else {
      $('.app-screen-end--player .app-screen-end--no-error').show();
      //addthis_share.email_vars.errors = $('.app-screen-end--player .app-screen-end--no-error').text();
    }

    addthis_share.email_vars.errors = self.gameData.piles.error;

    //affichage du score
    const divRank = $('.app-screen-end--rank-score');

    const erreurs = self.gameData.piles.error;
    const points = 10000 - (erreurs * 1000);
    self.gameData.score = Math.max(0, (points - ( self.gameData.timer * 10) ) );

    //console.log('Erreurs : ' + erreurs + ' Temps : ' + temps + ' Points : ' + points);
    //console.log('SCORE : erreurs - (temps * 10 ) => ' +  score );

    divRank.text(self.gameData.score);

    // sounds
    if(!Modernizr.ie) {
      self.settings.sounds.timer.audio.stop();
    }
    //console.log('addthis_share : ', addthis_share);

    self.playSound('endGame');

    if(typeof self.gameData.eventUrl !== 'undefined'){
      $.ajax ({
        url: self.settings.originBO + '/bo/api/get-rank',
        data: {
          score: self.gameData.score,
          eventUrl: self.gameData.eventUrl,
        },
        method: 'POST',
        dataType: 'json',
        success: function (data) {
          //console.log('Success Ajax player_rank : ', data);
          self.displayPlayerRank(data);
        },
        error: function (error) {
          console.error('Error Ajax : ', error);
          //self.eventPageNotAllowed();

        }
      });

      $('.app-save-your-score-button').show();
    }
  }

  /**
   *
   * Check Game
   *
   */

  checkGame() {
    const self = this;
    //console.log('checkGame : ', self.gameData);

    $('.app-pile-toTest').attr('data-count', self.gameData.piles.toTest);
    $('.app-pile-toTest--count span').text(self.gameData.piles.toTest);
    $('.app-screen-game .app-pile[data-type=negative]').attr('data-count', self.gameData.piles.negative).find('span').text(self.gameData.piles.negative);
    $('.app-screen-game .app-pile[data-type=vitek2]').attr('data-count', self.gameData.piles.vitek2).find('span').text(self.gameData.piles.vitek2);
    $('.app-screen-game .app-pile[data-type=vitekMS]').attr('data-count', self.gameData.piles.vitekMS).find('span').text(self.gameData.piles.vitekMS);


    if (self.gameData.activePlate !== '') {
      $('.app-screen-game').removeClass('table-is-empty');
    }
    else {
      $('.app-screen-game').addClass('table-is-empty');
      self.clearComputer();
    }
  }

  /**
   *
   * Challenge PhenoMATRIX
   *
   */

  challengePhenoMATRIX() {
    const self = this;
    //console.log('challengePhenoMATRIX : ', self.gameData);

    const $timer = $('.app-screen-challenge--timer');
    const $plates = $('.app-screen-challenge--plates--animated');
    const platesPerDay = $('.app-popup-challenge input[type=number]').val();

    $('.challenge-screen-is-opened').removeClass('challenge-screen-is-opened');
    $('.app-screen-challenge--plates--static').text(platesPerDay);
    $('.app-screen-challenge--plates--static').text(platesPerDay);
    $('.app-screen-challenge--timer--static').text(self.getClockTime(Math.round(platesPerDay * 3 / 2)));
    if(platesPerDay * 3 / 2 >= 3600) {
      $timer.addClass('as--long-time');
    }
    $timer.text(self.getClockTime(0));

    if($('.app-popup-challenge').data('bPopup')) {
      $('.app-popup-challenge').data('bPopup').close();
    }
    $('.app-screen').removeClass('is-active').addClass('is-hidden');
    $('.app-screen-challenge').addClass('is-active').removeClass('is-hidden');
    const duration = 6 * 1000;
    const pilesType = [0, 1, 2];
    self.playSound('challengeCounting');

    $({someValue: 0}).animate({someValue: platesPerDay}, {
      duration: duration,
      easing: 'easeInOutExpo', // can be anything
      step: function () { // called on every step
        // Update the element's text with rounded-up value:
        $timer.text(self.getClockTime(Math.round(this.someValue * 3 / 2)));
        $plates.text(Math.round(this.someValue));
      },
      complete: function () {
        $timer.text(self.getClockTime(Math.round(platesPerDay * 3 / 2)));
        $plates.text(Math.round(platesPerDay));
        $timer.addClass('is--finished');
        if(!Modernizr.ie) {
          self.settings.sounds.challengeCounting.audio.stop();
        }
        $('.app-page').delay(2.5 * 1000).queue(function () {
          $(this).addClass('challenge-screen-is-opened').dequeue();
        });
      }
    });


    let prevPiles = 0;
    let pilesMax = 0;
    const $piles = $('.app-screen-challenge .app-pile');
    $piles.each(function () {
      const $pile = $(this);
      pilesMax += $pile.data('max');
      $pile.attr('data-count', 0).find('span').text(0);
    });
    pilesMax = Math.min(pilesMax, platesPerDay);

    //console.log('pilesMax : ', pilesMax);
    $({someValue: 0}).animate({someValue: pilesMax}, {
      duration: duration,
      easing: 'easeInOutCubic', // can be anything
      step: function () { // called on every step
        const piles = Math.floor(this.someValue);
        if (prevPiles !== piles) {
          //console.log('piles : ', piles);
          //console.log('platesPerDay : ', platesPerDay);
          //console.log('this.someValue : ', this.someValue);
          const random = Math.floor(pilesType.length * Math.random());
          const $pile = $piles.eq(pilesType[random]);
          let count = parseInt($pile.attr('data-count')) + 1;
          $pile.attr('data-count', count).find('span').text(count);

          // if we arr at the max remove the index of full pile from the tested array
          if (count === $pile.data('max')) {
            pilesType.splice(random, 1);
          }
          prevPiles = piles;
        }
      },
      complete: function () {

        $piles.each(function () {
          const $pile = $(this);
          if (pilesMax < platesPerDay) {
            $pile.attr('data-count', $pile.data('max')).find('span').text($pile.data('max'));
          } else if ($pile.index() === 2) {
            let count = parseInt($pile.attr('data-count')) + 1;
            $pile.attr('data-count', count).find('span').text(count);
          }
        });

      }
    });
  }

  /**
   *
   * Test resize to add banner error if wrong ration
   *
   */

  testResize() {
    const self = this;
    //console.log('testResize : ', self.settings);

    self.settings.screen.prevRatio = self.settings.screen.ratio;
    self.settings.screen.prevWidth = self.settings.screen.width;
    self.settings.screen.prevHeight = self.settings.screen.height;
    self.settings.screen.width = $(window).width();
    self.settings.screen.height = $(window).height();
    self.settings.screen.ratio = self.settings.screen.width / self.settings.screen.height;

    const refRatio = self.settings.screen.refWidth / self.settings.screen.refHeight;

    if(self.settings.screen.width > self.settings.screen.refWidth &&
      self.settings.screen.height > self.settings.screen.refHeight &&
      (self.settings.screen.prevWidth <= self.settings.screen.refWidth || self.settings.screen.prevHeight <= self.settings.screen.refHeight)) {
      //console.log('come from smaller screen and know enouff big => no test ');
      $('html').removeClass('screen-is-vertical');
    }
    else if(self.settings.screen.width <= self.settings.screen.refWidth || self.settings.screen.height <= self.settings.screen.refHeight) {
      //console.log(' small screen');
      if(self.settings.screen.ratio >= refRatio && self.settings.screen.prevRatio <= refRatio) {
        //console.log('small screen -> vertical to horizontal');
        $('html').removeClass('screen-is-vertical');
      }
      else if(self.settings.screen.ratio < refRatio && self.settings.screen.prevRatio > refRatio) {
        //console.log('small screen -> horizontal to vertical');
        $('html').addClass('screen-is-vertical');
      }
    }

  }

  /**
   *
   * Get clock time
   *
   * @param time [int] time in second
   *
   * @return [string] mm:ss or mm'ss" (time in min and second)
   *
   */

  getClockTime(time) {
    const dTime = new Date(time * 1000);
    const sec = dTime.getSeconds();
    const min = dTime.getMinutes();
    const hr = dTime.getHours() - 1;

    return (hr > 0 ? hr + 'h' : '') + (min < 10 && time >= 10*60 ? '0' : '') + min + '\'' + (sec < 10 ? '0' : '') + sec + '"';
  }


  /**
   *
   * Test Event Page Availaibility
   *
   *
   */

  testEventPageAvailaibility(){
    const self = this;
    //console.log('Self Hash', self.hash);
    const appPopupRanking = $('.app-popup-ranking');

    // test if we are not on the Event Page
    if(!appPopupRanking.length) {
      //Do nothing and quit
      return false;
    }

    // else we are on the Event Page
    //console.log('app-popup-ranking existe');

    // test if there is no hastag OR no start width #event--
    if(self.hash === null || self.hash === '#event--' || !self.hash.startsWith('#event--') ){
      self.eventPageNotAllowed();
      return false;
    }

    //we are on an availaible Event page
    self.gameData.eventUrl = self.hash.substring(8);
    const urlAjax = self.settings.originBO + '/bo/api/is-active-event/' +  self.gameData.eventUrl;

    $('.switch-lang a').each(function () {
      $(this).attr('href', $(this).attr('href') + self.hash);
    });

    $.ajax ({
      url: urlAjax,
      //url: 'is_active_true.json',
      dataType: 'json',
      success: function (data) {
        //console.log('Game is allowed : Success Ajax : ', data);
        if(data.is_active === true && data.is_open === true){

        }else {
          self.eventPageNotAllowed();
        }
      },
      error: function (error) {
        console.error('Error Ajax : ', error);
        self.eventPageNotAllowed();
      }
    });
  }

  /**
   *
   * Test Event Page Not Allowed
   *
   *
   */

  displayRanking(data){
    const self = this;

    //console.log('Function displayRanking', data);
    //console.log('self.gameData', self.gameData);

    const ranking = data.ranking;
    const rankingTable = $('.app-popup-ranking--table');
    const tbodyRankingTable = rankingTable.find('tbody');
    let player;

    if(self.gameData.playerRank !== -1) {
      player = {
        id: self.gameData.playerId,
        rank: self.gameData.playerRank,
        name: self.gameData.playerName === '' ?
          ('"' + self.gameData.playerNameDefault + '"') :
          self.gameData.playerName,
        time: self.getClockTime(self.gameData.timer),
        score: self.gameData.score,
        saved: self.gameData.playerName !== '',
      };


      /*const arrayPlayerIndex = Math.min(5, self.gameData.playerRank - 1);
      ranking.splice(arrayPlayerIndex, arrayPlayerIndex === 5 ||
      (player.saved === true && ranking.length && ranking[arrayPlayerIndex].rank === self.gameData.playerRank)  ?
        1 : // replace the entry
        0, // insert an entry at the good pos
        player);

      //console.log('arrayPlayerIndex', arrayPlayerIndex);*/
      // if actual player is in the n first player of the array
      for (let i in ranking) {
        if(ranking[i].id === self.gameData.playerId) {
          ranking[i] = player;
        }
        //ranking[i].rank += (i > arrayPlayerIndex && player.saved === false ? 1 : 0);
      }
      //if is after the n first player we replace the last one by the current player
      if( self.gameData.playerRank > ranking[ranking.length - 1].rank) {
        ranking[ranking.length - 1] = player;
      }
    }


    if(ranking.length > 0){
      tbodyRankingTable.html('');
    }

    //console.log('ranking', ranking);

    for (let i in ranking) {
      //console.log('ranking[i].rank', ranking[i].rank);
      //console.log('[i]', parseInt(i, 10));
      //console.log('data.currentPlayerRank', self.gameData.playerId);

      const classes = [];
      if(typeof ranking[i].saved !== 'undefined') {
        classes.push('is-active');

        if(ranking[i].saved === false ) {
          classes.push('not-saved');
        }
      }
      tbodyRankingTable.append('<tr class="' + classes.join(' ') +  '"><td>' +
        ranking[i].rank + self.settings.ranks[i <= 3 ? i : 3] + '</td><td>' +
        ranking[i].name + '</td><td>' +
        ranking[i].time + '</td><td>' +
        ranking[i].score + '</td></tr>');
    }
  }

  displayPlayerRank(playerRankEndGame) {
    //console.log('Function Display Player Rank', playerRank);

    const self = this;
    const playerRankForm = $('.app-popup-saveYourScore--form');
    const playerRankPosition = $('.app-screen-end--rank-position');

    playerRankForm.html('');
    playerRankPosition.html('');

    //affichage du formulaire après avoir vidé la balise app-popup-saveYourScore--form
    playerRankForm.append(playerRankEndGame.form);

    //affichage du rang + nombre ordinal associé
    self.gameData.playerRank = playerRankEndGame.player_rank;
    const rankTh = self.gameData.playerRank -1 <= 3 ? self.gameData.playerRank -1 : 3;
    playerRankPosition.append(self.gameData.playerRank + self.settings.ranks[rankTh]);

    const appPopupSaveYourScore = $('.app-popup-saveYourScore');
    const form_name = $('#form_name');
    const form_save = $('#form_save');
    const inputPlaceholderData = appPopupSaveYourScore.data('placeholder');
    const validButtonData = appPopupSaveYourScore.data('validbtn');

    //ajout placeholder traduit pour input Name
    form_name.attr('placeholder',inputPlaceholderData);
    form_save.html('');
    //ajout value traduite pour input Save Your Score
    form_save.append(validButtonData);

    $('#form_score').val(self.gameData.score);
    $('#form_eventName').val(self.gameData.eventUrl);
    $('#form_time').val(self.getClockTime(self.gameData.timer));

  }

  eventPageNotAllowed(){

    $('.app-screen-eventPageNotAllowed').addClass('is-active');

  }

}


module.exports = AppGame;
